<template>
  <div class="content">
    <div class="title">库存管理</div>
    <a-row class="ptl10">
      <!-- <a-col :span="2" class="lh32">订单日期：</a-col> -->
      <a-col :span="7" class="lh32">
        <div>订单日期：</div>
        <a-range-picker @change="onChangeTime" style="width:250px">
          <a-icon slot="suffixIcon" type="calendar" />
        </a-range-picker>
      </a-col>
      <!-- <a-col :span="2" class="lh32">供货人：</a-col> -->
      <a-col :span="5" class="lh32">
        <div>供货人：</div>
        <a-select
          v-model="reqForm.supplierId"
          show-search
          placeholder="请选择供货人"
          style="width:150px"
          :filter-option="filterOption"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
        >
          <a-select-option :value="item.id" v-for="item in suppliers" :key="item.code">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-col>
      <!-- <a-col :span="2" class="lh32">品类：</a-col> -->
      <a-col :span="5" class="lh32">
        <!-- <a-input placeholder="" /> -->
        <div>品类：</div>
        <a-select
          v-model="reqForm.merchandiseCode"
          show-search
          placeholder="请选择品类"
          style="width:150px"
          :filter-option="filterOption"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
        >
          <a-select-option :value="item.code" v-for="item in categorys" :key="item.code">
            {{item.label}}
          </a-select-option>
        </a-select>
      </a-col>
      <!-- <a-col :span="2" class="lh32">下游企业:</a-col> -->
      <a-col :span="5" class="lh32">
        <div>下游企业：</div>
        <!-- <a-input placeholder="" /> -->
        <a-select
          v-model="reqForm.downstreamId"
          show-search
          placeholder="请选择下游企业"
          style="width:150px"
          :filter-option="filterOption"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
        >
          <a-select-option :value="item.id" v-for="item in downstreams" :key="item.id">
            {{item.downstreamName}}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="1" class="lh32">
        <a-button type="primary" @click="reqForm.pageNum = 1;getWarehouseList()">
           查询
        </a-button>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-between" class="total-row" v-if="selectedRows.length>0">
      <a-col>
        <a-icon type="check-circle" style="margin-right:10px"/> 商品分类：{{catValueText}}，总净重：{{allTotalNetWeight}}吨，总金额：{{allTotalPrice}}元
      </a-col>
      <a-col>
        <a-button type="primary" @click="onHandlerCreate" size="small" class="create-btn">
          <a-icon type="right-square"></a-icon> 创建订单
        </a-button>
      </a-col>
    </a-row>

    <a-table
      style="margin-top:15px"
      :columns="columns"
      :data-source="data"
      :row-selection="rowSelection"
      :expanded-row-keys.sync="expandedRowKeys"
      :pagination="{
          size: 'middle',
          total: total,
          current: reqForm.pageNum,
          pageSize: reqForm.pageSize,
          showTotal: total => `共有 ${total} 条数据`,
          showSizeChanger: true,
          showQuickJumper: true,
          onShowSizeChange: (current, pageSize) => {
            reqForm.pageSize = pageSize
            reqForm.pageNum = 1
            this.getWarehouseList()
          },
          onChange: (pageNum) => {
            reqForm.pageNum = pageNum
            this.getWarehouseList()
          }
        }"
    >
      <!-- <template slot="supplierName" slot-scope="text,record">
        <a @click="getWarehouseInfo(record.id)">{{ text }}</a>
      </template> -->
      <template slot="action" slot-scope="text, record">
          <a @click="getWarehouseInfo(record.id)">查看详情</a>
        </template>
    </a-table>

    <a-modal
      title="入库详情"
      :visible="visible"
      :footer="null"
      :width="800"
      @cancel ='visible=false'
    >
      <div>
        <div>
          <a-row justify="space-between" type="flex">
            <a-col >
              <span class="pop-title">入库单号：{{wInfo.id}}</span>
            </a-col>
            <a-col>
              <a-popconfirm :title="`是否删除?`" @confirm="onHandlerDelete(wInfo.id)">
                <a-button type="primary" @click="visible = true" size="small" class="create-btn">
                   删除入库
                </a-button>
              </a-popconfirm>
            </a-col>
          </a-row>
          <div class="pop-detail">
            <a-row >
              <a-col :span="10">
                供应商：{{wInfo.supplierName}}
              </a-col>
              <a-col :span="10">
                  企业统一代码：{{wInfo.uniformCode}}
              </a-col>
            </a-row>
            <a-row >
              <a-col :span="10">
                收货下游：{{wInfo.downstreamName}}
              </a-col>
              <!-- <a-col :span="10">
                  入库仓库：{{wInfo.warehouseAddress}}
              </a-col> -->
            </a-row>
          </div>
          <a-row justify="space-between" type="flex">
            <a-col class="pop-title">
              入库详情
            </a-col>
          </a-row>
          <div class="pop-detail">
            <a-row >
              <a-col :span="10">
                入库时间：{{wInfo.createdTime}}
              </a-col>
              <a-col :span="10">
                车牌号：{{wInfo.licenseNum}}
              </a-col>
            </a-row>
            <a-row >
              <a-col :span="10">
                一级分类：{{wInfo.firstCatValue}}
              </a-col>
              <a-col :span="10">
                二级分类：{{wInfo.secondCatValue}}
              </a-col>
            </a-row>
            <a-row >
              <a-col :span="10">
                毛重：{{wInfo.grossWeight}}吨
              </a-col>
              <a-col :span="10">
                皮重：{{wInfo.tareWeight}}吨
              </a-col>
            </a-row>
            <a-row >
              <a-col :span="10">
                扣杂：{{wInfo.impuritiesWeight}}吨
              </a-col>
              <a-col :span="10">
                净重：{{wInfo.netWeight}}吨
              </a-col>
            </a-row>
            <a-row >
              <a-col :span="10">
                商品单价：{{wInfo.unitPrice}}元/吨
              </a-col>
              <a-col :span="10">
                商品金额：{{wInfo.totalPrice}}元
              </a-col>
            </a-row>
          </div>
          <a-row justify="space-between" type="flex">
            <a-col class="pop-title">
              入库照片
            </a-col>
          </a-row>
          <a-row type="flex" :gutter="14" class="row">
            <a-col :span="6" v-for="(pic,index) in wInfo.image" :key="index">
              <div class="freight-photo">
                <img :src="pic.imageUrl" alt="">
                <p class="info">{{pic.imageType | typeName}}</p>
                <div class="mask" @click="show(pic.imageUrl)">
                  <a-space>
                    <a-icon type="eye"></a-icon>
                  </a-space>
                </div>
              </div>
            </a-col>
            
          </a-row>
        </div>
      </div>
    </a-modal>
    
    <!-- 图片放大 -->
    <a-modal :width="800" :visible="imgVisible" :footer="null" @cancel="imgVisible = false">
      <img alt="营业执照照片" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { warehouseList, categorysList, suppliersList, warehouseInfo, warehouseDelete, downstreamList } from '@/api/buyerCenter'
import { getPreview } from '@/api/dsm'
const columns = [
  {
    title: '供货人',
    dataIndex: 'supplierName',
    key: 'supplierName',
    scopedSlots: { customRender: 'supplierName' },
  },
  {
    title: '商品分类',
    dataIndex: 'catValue',
    key: 'catValue',
  },
  {
    title: '车牌号',
    dataIndex: 'licenseNum',
    width:'110px',
    key: 'licenseNum',
  },
  {
    title: '毛重(吨)',
    dataIndex: 'grossWeight',
    key: 'grossWeight',
    align:'right'
  },
  {
    title: '皮重(吨)',
    dataIndex: 'tareWeight',
    key: 'tareWeight',
    align:'right'
  },
  // {
  //   title: '扣杂(吨)',
  //   dataIndex: 'impuritiesWeight',
  //   key: 'impuritiesWeight',
  // },
  {
    title: '净重(吨)',
    dataIndex: 'netWeight',
    key: 'netWeight',
    align:'right'
  },
  {
    title: '商品单价(元/吨)',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    align:'right'
  },
  {
    title: '商品金额(元)',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    align:'right'
  },
  {
    title: '入库时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width:'100px',
    scopedSlots: { customRender: 'action' }
  },
];

// const rowSelection = {
//   onChange: (selectedRowKeys, selectedRows) => {
//     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
//   },
//   onSelect: (record, selected, selectedRows) => {
//     console.log(record, selected, selectedRows);
//   },
//   onSelectAll: (selected, selectedRows, changeRows) => {
//     console.log(selected, selectedRows, changeRows);
//   },
// };
export default {
  data() {
    return {
      data:[],
      columns,
      total: 0,
      expandedRowKeys: [],
      categorys:[],//筛选类别列表
      visible: false,
      suppliers:[],//筛选供应商列表
      downstreams:[{id:'',downstreamName:'全部'}],//下游企业列表
      reqForm:{
        pageNum:1,
        pageSize:10,
        purchaserId: this.$route.query.entityId,
        from:'',
        to:'',
        supplierId:'',
        merchandiseCode:'',
        downstreamId:''
      },
      wInfo:{},
      selectedRows:[], //选中订单

      previewImage: '',
      imgVisible: false
    };
  },
  mounted(){
    this.getWarehouseList()
    this.getCategorysList()
    this.getSuppliersList()
    this.getDownstreamList()
  },
  filters:{
    typeName(type){
      const types = {
        IN_LICENSE_NUM:'车牌',
        IN_WEIGHING:'过磅',
        IN_WEIGH_LIST:'磅单',
        IN_VERIFIED:'质检',
        IN_GOODS:'货物',
        IN_ETC_OR_HEIG_INVOICE:'高速票/ETC',
        IN_TRANSPORT_INVOICE:'运输发票',
        WA_START_LICENSE_NUM:'运单出发车牌照',
        WA_END_LICENSE_NUM:'运单终点车牌照',
        WA_START_WEIGH:'发车磅单',
        // END_WEIGH:'终点磅单',
      }
      return types[type]
    }
  },
  computed:{
    //总金额
    allTotalPrice(){
      if(!this.selectedRows.length){
        return 0
      }
      let price = this.selectedRows.reduce((prev,next)=>{
        prev += next.totalPrice
        return prev
      },0)
      return price.toFixed(2)
    },
    catValueText(){
      if(!this.selectedRows.length){
        return ''
      }
      
      return this.selectedRows[0].catValue
    },
    //总金额
    allTotalNetWeight(){
      if(!this.selectedRows.length){
        return 0
      }
      let netWeight = this.selectedRows.reduce((prev,next)=>{
        prev += next.netWeight
        return prev
      },0)
      return netWeight
    },
    rowSelection() {
      return {
        columnTitle: ' ',
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
          //重新赋值刷新table的data
          this.data = [...this.data]
        },
        getCheckboxProps: (recode) => {
          let selectedRow_ = this.selectedRows[0]||{}
          return {
            props: {
              //当选择了订单后 供货人、商品分类、单价不一致都不能一起选择去支付
              disabled: this.selectedRows.length>0&&(selectedRow_.supplierName!==recode.supplierName||selectedRow_.catValue!==recode.catValue||selectedRow_.downstreamId!==recode.downstreamId)
            },
          }
        },
      };
    },
  },
  methods: {
    onHandlerCreate(){
      getPreview({ inIds: this.selectedRowKeys.toString() }).then((res) => {
        if(res.code==200){
          this.$router.push(`/stockorder?entityId=${this.$route.query.entityId}&inIds=${this.selectedRowKeys.toString()}`)
        }else{
          this.$message.error(res.message)
        }
      })
      
    },
    getDownstreamList(){
      downstreamList({
        purchaserId: this.$route.query.entityId
      }).then(res=>{
        if(res.code==200){
          this.downstreams = [...this.downstreams,...res.data]
        }else{
          this.$message.error(res.message)
        }
      })
    },
    show (url) {
      this.previewImage = url
      this.imgVisible = true
    },
    filterOption(input,option){
      console.log(input)
      console.log(option)
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onChangeTime(date, dateString) {
      this.reqForm.beginTime = dateString[0]
      this.reqForm.endTime = dateString[1]
      // this.getlist()
    },
    //删除
    onHandlerDelete(id){
      warehouseDelete(id).then(res=>{
        if(res.code==200){
          this.visible = false;
          this.getWarehouseList()
          this.$message.success(res.message)
        }else{
          this.$message.error(res.message)
        }
      })
    },
    //查看详情
    getWarehouseInfo(id){
      this.visible = true
      warehouseInfo(id).then(res=>{
        if(res.code==200){
          this.wInfo = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    //供应商列表
    getSuppliersList(){
      let {entityId} = this.$route.query
      suppliersList({
        purchaserId:entityId
      }).then(res=>{
        if(res.code==200){
          this.suppliers = [{id: "", name: "全部"},...res.data]
        }else{
          this.$message.error(res.message)
        }
      })
    },
    //获取对应类别列表
    getCategorysList(){
      let {entityId} = this.$route.query
      categorysList({
        purchaserId:entityId
      }).then(res=>{
        if(res.code==200){
          this.categorys = [{code: "", label: "全部"},...res.data]
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getWarehouseList(){
      let {entityId} = this.$route.query
      warehouseList({...this.reqForm, purchaserId:entityId}).then(res=>{
        if(res.code==200){
          this.data = res.data.list.map(item=>{
            item.key =item.id
            return item
          })
          this.total = res.data.total
        }else{
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  padding: 20px 10px 20px 20px;
  background-color: #ffffff;
  .title {
    padding-left: 10px;
    font-weight: 650;
    font-style: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.847058823529412);
    line-height: 28px;
  }
  .ant-row {
    padding: 20px 0 0 20px;
  }
  .lh32{
    display: flex;
    line-height: 30px;
  }
  .total-row{
    margin: 15px 20px 0;
    padding: 10px;
    // background-color: #80ffff;
    border-radius: 5px;
    .ant-col{
      display: flex;
      align-items: center;
    }
  }
  // .create-btn{
  //   background-color: rgba(132, 0, 255, 0.7);
  //   border-color: rgba(132, 0, 255, 0.7);
  // }
}
.pop-title{
  font-weight: bold;
  color: #000;
}
.pic-col{
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  .z_pic{
    margin-left: 10px;
  }
}
.pop-detail{
  margin-bottom: 15px;
  .ant-col{
    margin-top: 5px;
  }
}

.freight-photo {
  border: 1px solid #ebebeb;
  position: relative;
  margin-top: 20px;
  .info {
    height: 40px;
    line-height: 40px;
    background: #fff;
    text-align: center;
    font-size: 16px;
    border-top: 1px solid #f2f2f2;
    margin-bottom: 0;
  }

  img {
    background: #f6f6f6;
    width: 100%;
    height: 160px;
    transition: all .2s;
  }
  .mask {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all .3s;
    opacity: 0;
    position: absolute;
    top: 0;
    height: 160px;
    width: 100%;
    background: rgba(0, 0, 0, .4);
    font-size: 18px;
  }
  &:hover .mask{
    opacity: 1;
  }
}
</style>
