import request from '@/utils/request'

// 库存-订单创建信息预览
export function getPreview(data){
    return request({
        url:'/freight/warehouse/in/batch/orders/preview',
        method:'post',
        data
    })
}

// 库存-订单创建-确认
export function create(data){
    return request({
        url:'/freight/warehouse/in/batch/orders/create',
        method:'POST',
        data
    })
}